import type IConfigDev from './config.development.json';

export let config: typeof IConfigDev = { ...APP_CONFIG };

declare const APP_CONFIG: typeof IConfigDev;
console.log('start fetching config');
const response = fetch('/config.json').then((request) => request.json());
console.log('finish fetching config');
export async function loadConfig(): Promise<void> {
    console.log('start remote  config');
    const remoteConfig = await response;
    console.log('finish remote  config');
    config = { ...APP_CONFIG, ...remoteConfig };
}
