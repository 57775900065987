import { config } from '../../../config/config';

import { isInApp } from './is-app';

const LOG_TAG = '[SportNCo App Auth]';

function tryPostMessageAppWrapper(message: unknown) {
    console.log(LOG_TAG, 'sending post message', message);
    console.log(LOG_TAG, 'sending post message to origin,', config.SPORTNCO_NATIVE_APP.ORIGIN);
    try {
        if (!window.parent) {
            console.error(LOG_TAG, 'no parent window to send message to');
            return;
        }

        window.parent.postMessage(message, config.SPORTNCO_NATIVE_APP.ORIGIN);
    } catch (e) {
        console.warn(LOG_TAG, 'unable to send post message', e);
    }
}

/**
 * Requests the authentication details from the sportnco native app if we are running in the native app.
 */
export async function getAppAuthentication() {
    console.log(config.SPORTNCO_NATIVE_APP.ORIGIN,'origin window');
    if (!isInApp() || !config.SPORTNCO_NATIVE_APP.IS_ENABLED) {
        return;
    }

    const isExcludedPath = config.SPORTNCO_NATIVE_APP.AUTH_POSTMESSAGE_EXCLUDED_PATHS.some(
        (path: any) => {
            return window.location.pathname.includes(path);
        },
    );

    if (isExcludedPath) {
        return;
    }

    return new Promise<void>((resolve) => {
        const messageHandler = (message: MessageEvent) => {
            if (!message.data || message.data.message !== 'sportnco_send_tokens') {
                // First message to iOS is always empty
                // Inform the parent window we're ready for the tokens once again.
                tryPostMessageAppWrapper({ message: 'external_tokens_iframe_ready' });
                return;
            }

            try {
                const eventData = message.data.args;

                if (!eventData) {
                    console.log(LOG_TAG, 'received empty post message', message.data);
                    return;
                }

                const access_token = eventData.auth_token;
                const refresh_token = eventData.refresh_token;
                const id_token = eventData.id_token;

                if (!access_token || !refresh_token || !id_token) {
                    console.log(LOG_TAG, 'received empty token information');
                    return;
                }

                // Decode id_token, these are not validated til we send them to the server
                // Presume these contain unsanitized data
                const profile = JSON.parse(atob(id_token.split('.')[1]));
                const accessToken = JSON.parse(atob(access_token.split('.')[1]));

                const state = accessToken.sid;

                const storedAuthenticationState = {
                    access_token,
                    refresh_token,
                    id_token,
                    profile,
                    state,
                    expires_at: accessToken.exp,
                    token_type: accessToken.typ,
                    scope: accessToken.scope,
                };

                const key = `oidc.user:${config.AUTH_ENDPOINT}realms/${config.KEYCLOAK_REALM}:${config.SPORTNCO_NATIVE_APP.KEYCLOAK_CLIENT_ID}`;
                localStorage.setItem(key, JSON.stringify(storedAuthenticationState));

                console.log(LOG_TAG, 'restored authentication from application');
            } catch (e) {
                console.error(LOG_TAG, 'Could not restore from app state', e);
            } finally {
                window.removeEventListener('message', messageHandler);
                resolve();
            }
        };

        window.addEventListener('message', messageHandler);
        tryPostMessageAppWrapper({ message: 'external_tokens_iframe_ready' });
    });
}
